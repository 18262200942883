import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input, Switch } from "../components";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Row } from "react-bootstrap";
import { Moeda, ObjetoVazio } from "../components/Formatar";

const Financeiro = () => {
  let uid = auth.currentUser?.uid;

  const params = new URLSearchParams(window.location.search);

  const periodo = params.get("periodo");
  const minimo = params.get("minimo");
  const mes = params.get("mes");

  let valor = 15.5;

  if (periodo === "0125" || "0225") valor = 16;

  const [dados, setDados] = useState({});
  const [vales, setVales] = useState(minimo);
  const [ferias, setFerias] = useState(false);
  const [erro, setErro] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const Validacao = () => {
    let ret = {};
    if (!dados["urlPagto" + periodo]) ret.pagto = "Envio obrigatório";
    if (ferias) {
      if (!dados["urlFerias" + periodo])
        ret.ferias = "Envie um documento comprovando suas férias";
    } else {
      if (vales < minimo)
        ret.pagto =
          "Pagamentos inferiores ao valor mínimo somente no período de férias";
    }
    if (ObjetoVazio(ret)) return "";
    else return ret;
  };

  const handleSwitch = () => {
    setFerias(!ferias);
    setVales(minimo);
  };

  const setData = async () => {
    await setDoc(doc(db, "ebserh2025", uid), {
      ...dados,
      ["vales" + periodo]: vales,
    });
  };

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `ebserh2025/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setDados({ ...dados, [docUrl]: url, [docPath]: "Enviado" });
          setLoading(false);
        });
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const val = Validacao();

    if (val) {
      setErro(val);
      setLoading(false);
    } else {
      setData().then(() => window.location.reload(false));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "ebserh2025", uid));

    const data = qry.data();

    if (data?.status === "a") {
      if (data?.aceite25) {
        setDados(data);
        setLoading(false);
      } else {
        navigate("../renovacao");
      }
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  const checkParams = () => {
    if (!periodo || !mes || !minimo) navigate("../home");
  };

  useEffect(() => {
    checkLogin();
    checkParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}

      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {!dados["vales" + periodo] ? (
        <Frame
          title={`Financeiro ${mes}`}
          body={
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <div className="justify px-3 mb-4 lh-lg">
                <p>
                  <i>
                    Para pagar sua mensalidade, selecione a quantidade de vales
                    e faça um pix para a chave abaixo. Caso tenha desconto de
                    férias, envie o comprovante de férias para selecionar uma
                    quantidade menor.
                  </i>
                </p>
                <div className="mb-4">
                  <RangeSlider
                    value={vales}
                    onChange={(e) => setVales(e.target.value)}
                    min={ferias ? 1 : minimo}
                    max={60}
                  />
                  <div>
                    <h6>{`Vales: ${vales}`}</h6>
                    <h6>{`Valor: ${Moeda(vales * valor)}`}</h6>
                    <h6>Pix: 92.189.612/0001-92</h6>
                  </div>
                </div>

                <div className="mb-4">
                  <Col sm={12}>
                    <Input
                      id={"urlPagto" + periodo}
                      label="Comprovante de Pagamento"
                      type="file"
                      className={erro.pagto && "is-invalid"}
                      erro={erro.pagto}
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(e) =>
                        uploadFile(
                          e.target.files[0],
                          "pagto" + periodo,
                          "urlPagto" + periodo
                        )
                      }
                      url={dados["urlPagto" + periodo]}
                    />
                  </Col>
                </div>

                <Switch
                  label="Estarei de férias nesse mês"
                  id="ferias"
                  className="lh-base"
                  checked={ferias}
                  onChange={handleSwitch}
                />

                {ferias && (
                  <div className="mt-4">
                    <Col sm={12}>
                      <Input
                        id={"urlFerias" + periodo}
                        disabled={!ferias}
                        label={"Comprovante de Férias"}
                        type="file"
                        className={erro.ferias && "is-invalid"}
                        erro={erro.ferias}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "ferias" + periodo,
                            "urlFerias" + periodo
                          )
                        }
                        url={dados["urlFerias" + periodo]}
                      />
                    </Col>
                  </div>
                )}

                <Row className="mt-4">
                  <Col className="d-grid">
                    <button className="btn btn-primary">Enviar</button>
                  </Col>
                </Row>
              </div>
            </form>
          }
        />
      ) : (
        <Frame
          title={`Financeiro ${mes} 2024`}
          body={
            <>
              <p>Pagamento Recebido.</p>
              <p>Obrigado por utilizar nossos serviços.</p>
            </>
          }
        />
      )}
    </>
  );
};

export default Financeiro;
